 <template>
  <div class="p-grid">
    <div class="p-col-12">
      <Panel header="Configurador de DataTables">
        <Toolbar class="p-mb-3">
          <template v-slot:left>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success"
              @click="showCreateDatatable()"
            />
          </template>
          <template v-slot:right> </template>
        </Toolbar>
        <DataTable
          ref="dt"
          :value="datatables"
          class="p-datatable-sm"
          v-model:selection="selectedDatatable"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :rowsPerPageOptions="[10, 20, 50]"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} usuários"
        >
          <template #header>
            <div
              class="
                table-header
                p-d-flex p-flex-column p-flex-md-row p-jc-md-between
              "
            >
              <h5>Datatables</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
          <Column header="#" field="id">
            <template #body="slotProps">
              <span class="p-column-title">#</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column header="Nome" field="nome">
            <template #body="slotProps">
              <span class="p-column-title">Nome:</span>
              {{ slotProps.data.nome.toUpperCase() }}
            </template>
          </Column>
          <Column header="Status" field="status">
            <template #body="slotProps">
              <span class="p-column-title">Status:</span>
              <span
                v-if="slotProps.data.status === true"
                :class="'product-badge status-' + slotProps.data.status"
                @click="updateStatus(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA INATIVAR'"
                style="cursor: pointer"
                >Ativo</span
              >
              <span
                v-if="slotProps.data.status === false"
                :class="'product-badge status-' + slotProps.data.status"
                @click="updateStatus(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA ATIVAR'"
                style="cursor: pointer"
                >Inativo</span
              >
              <!--{{ slotProps.data.status }}-->
            </template>
          </Column>
          <Column header="Dt. Cadastro" field="dt_cadastro">
            <template #body="slotProps">
              <span class="p-column-title">Dt. Cadastro:</span>
              {{ $DateTime.formatDateTime24hours(slotProps.data.dt_cadastro) }}
            </template>
          </Column>
          <Column header="Dt. Atualização" field="dt_atualizacao">
            <template #body="slotProps">
              <span class="p-column-title">Dt. Atualização:</span>
              <span v-if="slotProps.data.dt_atualizacao">{{
                $DateTime.formatDateTime24hours(slotProps.data.dt_atualizacao)
              }}</span>
              <span v-else>NÃO ATUALIZADO.</span>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-cog"
                class="p-button-rounded p-button-info p-mr-2"
                @click="showConfigurador(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA ABRIR O CONFIGURADOR'"
              />

              <Button
                icon="pi pi-eye"
                class="p-button-rounded p-button-info p-mr-2"
                :disabled="!slotProps.data.status"
                @click="showPreviewDataTable(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA ABRIR A VISUALIZAÇÃO'"
              />
              <Button
                icon="pi pi-unlock"
                class="p-button-rounded p-button-secondary p-mr-2"
                @click="showPermissoesDataTable(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA ABRIR AS PERMISSÕES'"
              />
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="showUpdateDatatable(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA EDITAR'"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="showDeleteDataTable(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA EXCLUIR'"
              />
            </template>
          </Column>
        </DataTable>
      </Panel>
    </div>
  </div>
  <!--MODAL CADASTRAR/ALTERAR-->
  <Dialog
    v-model:visible="datatableDialog"
    :header="titleDialog"
    :style="{ width: '800px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <form @submit.prevent="saveOrUpdateDataTable(!v$.datatable.$invalid)">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
          <label for="nome">Nome</label>
          <InputText
            id="nome"
            v-model="v$.datatable.nome.$model"
            placeholder="Digite o nome."
            autofocus
            :class="{
              'p-invalid': submitted && v$.datatable.nome.$invalid,
            }"
          />
          <small
            class="p-invalid"
            v-if="submitted && v$.datatable.nome.$invalid"
            >Nome é obrigatório. Min.3 e Max.50.</small
          >
        </div>

        <div class="p-field p-col-12 p-md-6">
          <label for="status">Status</label><br />
          <InputSwitch
            id="status"
            v-model="v$.datatable.status.$model"
            :class="{
              'p-invalid': submitted && v$.datatable.status.$invalid,
            }"
          />
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid" v-if="datatable.dt_cadastro">
        <div class="p-field p-col-12 p-md-6">
          <label for="dt_cadastro">Dt. Cadastro</label><br />
          <small>{{
            $DateTime.formatDateTime24hours(datatable.dt_cadastro)
          }}</small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="dt_atualizacao">Dt. Atualização</label><br />
          <small v-if="datatable.dt_atualizacao">{{
            $DateTime.formatDateTime24hours(datatable.dt_atualizacao)
          }}</small>
          <small v-else>Não Atualizado.</small>
        </div>
      </div>
      <!--
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
          <label for="dtCadastro">Dt. Cadastro</label>
          <InputText id="dtCadastro" v-model="datatable.dt_cadastro" disabled />
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="dtAtualizacao">Dt. Atualização</label>
          <InputText
            id="dtAtualizacao"
            v-model="datatable.dt_atualizacao"
            disabled
          />
        </div>
      </div>
    -->
      <Button
        label="Salvar"
        class="p-button-raised p-mr-2"
        icon="pi pi-check"
        type="submit"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </form>
    <template #footer> </template>
  </Dialog>
  <!--FIM MODAL CADASTRAR-->

  <!--MODAL CONFIGURADOR DE COLUNAS-->
  <Dialog
    v-model:visible="configuradorDialog"
    :header="titleDialog"
    :style="{ width: '1200px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <Toast group="tl" />
    <Fieldset :legend="'DATATABLE - ' + titleDialog">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
          <label for="nome">Nome do DataTable</label>
          <InputText
            id="nome"
            v-model="datatable.nome"
            placeholder="Digite o nome."
            disabled
          />
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="status">Status</label><br />
          <InputSwitch id="status" v-model="datatable.status" />
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid" v-if="datatable.dt_cadastro">
        <div class="p-field p-col-12 p-md-6">
          <label for="dt_cadastro">Dt. Cadastro</label><br />
          <small>{{
            $DateTime.formatDateTime24hours(datatable.dt_cadastro)
          }}</small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="dt_atualizacao">Dt. Atualização</label><br />
          <small v-if="datatable.dt_atualizacao">{{
            $DateTime.formatDateTime24hours(datatable.dt_atualizacao)
          }}</small>
          <small v-else>Não Atualizado.</small>
        </div>
      </div>
    </Fieldset>
    <br />
    <Fieldset legend="CUSTOMIZADOR DE COLUNAS">
      <p class="p-text-center">
        <Button
          type="button"
          icon="pi pi-plus"
          class="p-button-success"
          label="NOVA COLUNA"
          @click="addColumn"
          :disabled="colums.params.length === 12"
          v-tooltip.top="'ADD COLUNA'"
          style="margin-right: 0.5em"
        />
      </p>
      <div style="margin-top: 2em">
        <transition-group
          name="dynamic-box"
          tag="div"
          class="p-fluid p-formgrid p-grid"
        >
          <div
            v-for="(dados, key) in colums.params"
            :key="key"
            class="p-field p-col-12 p-md-3"
          >
            <Card>
              <template #header>
                <p class="p-text-right">
                  <Button
                    type="button"
                    icon="pi pi-times"
                    v-tooltip.top="'REMOVER ESTA COLUNA'"
                    class="p-button-danger"
                    @click="removeColumn(colums.params[key])"
                  />
                </p>
              </template>
              <template #title>COLUNA {{ key + 1 }}</template>
              <template #content>
                <div class="p-field p-grid">
                  <label class="p-col-12 p-mb-2 p-md-3">Nome </label>
                  <div class="p-col">
                    <InputText
                      v-model="colums.params[key].nome"
                      placeholder="Nome do campo"
                      :class="{
                        'p-invalid': submitted && !colums.params[key].nome,
                      }"
                    />
                    <small
                      class="p-invalid"
                      v-if="submitted && !colums.params[key].nome"
                      >Campo obrigatorio.</small
                    >
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label class="p-col-12 p-mb-2 p-md-3">Tipo </label>
                  <div class="p-col">
                    <Dropdown
                      v-model="colums.params[key].tipo"
                      placeholder="Tipo do Campo"
                      :options="tipoColumns"
                      :class="{
                        'p-invalid': submitted && !colums.params[key].tipo,
                      }"
                    />
                    <small
                      class="p-invalid"
                      v-if="submitted && !colums.params[key].tipo"
                      >Campo obrigatorio.</small
                    >
                  </div>
                </div>
                <div class="p-field p-grid">
                  <label class="p-col-12 p-mb-2 p-md-3">Ordem</label>
                  <div class="p-col">
                    <InputNumber
                      mode="decimal"
                      showButtons
                      :min="1"
                      :max="12"
                      v-model="colums.params[key].ordem"
                      placeholder="Ordem do Campo"
                      :class="{
                        'p-invalid': submitted && !colums.params[key].ordem,
                      }"
                    />
                    <small
                      class="p-invalid"
                      v-if="submitted && !colums.params[key].ordem"
                      >Ordem do campo é obrigatorio.</small
                    >
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </transition-group>
      </div>
    </Fieldset>
    <template #footer>
      <Button
        label="Salvar"
        icon="pi pi-check"
        class="p-button-raised"
        @click="saveOrUpdateColumsToDataTable()"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </template>
  </Dialog>
  <!--FIM MODAL CONFIGURADOR DE COLUNAS-->

  <!--MODAL DELETAR COLUNA-->
  <Dialog
    v-model:visible="deleteColumToDatatable"
    header="Deseja realmente excluir essa coluna?"
    :style="{ width: '450px' }"
    :modal="true"
    @hide="deleteColumToDatatable = false"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span>
        Tem certeza de que deseja <b> excluir esta Coluna</b>, em caso de
        exclusão ocorrerá perda dos dados não havendo reversão.
        <b
          >Se caso houver registro atrelados a esta Coluna não será possível
          exclui-la.</b
        >
      </span>
    </div>
    <template #footer>
      <Button
        label="Sim"
        icon="pi pi-check"
        class="p-button-raised"
        @click="deleteColumDatatable()"
      />
      <Button
        label="Não"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </template>
  </Dialog>
  <!--FIM MODAL DELETAR COLUNA-->

  <!--MODAL DELETAR DATATABLE-->
  <Dialog
    v-model:visible="deleteDatatableDialog"
    header="Confirmação de Exclusão."
    :style="{ width: '450px' }"
    :modal="true"
    @hide="deleteColumToDatatable = false"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span>
        Tem certeza de que deseja <b> excluir este Datatable</b>, em caso de
        exclusão ocorrerá perda dos dados não havendo reversão.
        <b
          >Se caso houver registro atrelados a este Datatable não será possível
          exclui-lo.</b
        >
      </span>
    </div>
    <template #footer>
      <Button
        label="Sim"
        icon="pi pi-check"
        class="p-button-raised"
        @click="deleteDatatable()"
      />
      <Button
        label="Não"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </template>
  </Dialog>
  <!--FIM MODAL DELETAR DATATABLE-->

  <!--MODAL PERMISSOES DO DATATABLE-->
  <Dialog
    v-model:visible="permissoesDialog"
    :header="titleDialog"
    :style="{ width: '1200px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <Toast group="tl" />
    <Fieldset :legend="'DATATABLE - ' + titleDialog">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
          <label for="nome">Nome do DataTable</label>
          <InputText
            id="nome"
            v-model="datatable.nome"
            placeholder="Digite o nome."
            disabled
          />
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="status">Status</label><br />
          <InputSwitch id="status" v-model="datatable.status" />
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid" v-if="datatable.dt_cadastro">
        <div class="p-field p-col-12 p-md-6">
          <label for="dt_cadastro">Dt. Cadastro</label><br />
          <small>{{
            $DateTime.formatDateTime24hours(datatable.dt_cadastro)
          }}</small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="dt_atualizacao">Dt. Atualização</label><br />
          <small v-if="datatable.dt_atualizacao">{{
            $DateTime.formatDateTime24hours(datatable.dt_atualizacao)
          }}</small>
          <small v-else>Não Atualizado.</small>
        </div>
      </div>
    </Fieldset>
    <br />
    <Fieldset legend="DATATABLE - USUÁRIOS E PERMISSÕES">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12" style="padding: 5px">
          <Listbox
            v-model="datatable.usuarios"
            :options="usuarios"
            :multiple="true"
            :filter="true"
            optionLabel="nome"
            listStyle="max-height:250px"
            filterPlaceholder="DIGITE O NOME DO USUÁRIO."
            emptyFilterMessage="Nenhum Resultado..."
            emptyMessage="Nenhum Resultado..."
          >
            <template #header> </template>
            <template #option="slotProps">
              <div class="product-list-detail">
                <i class="pi pi-unlock product-category-icon p-mr-3"> </i>
                <span class="product-category">
                  {{ slotProps.option.nome.toUpperCase() }} |
                  {{ slotProps.option.setor.sigla.toUpperCase() }} -
                  {{ slotProps.option.setor.nome.toUpperCase() }}
                </span>
              </div>
            </template>
          </Listbox>
        </div>

        <!--  CADASTRO DE USUARIO -->
      </div>
    </Fieldset>
    <template #footer>
      <Button
        label="Salvar"
        icon="pi pi-check"
        class="p-button-raised"
        @click="saveOrUpdateDataTable(true)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </template>
  </Dialog>
  <!--FIM MODAL PERMISSOES DO DATABLE-->
</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import { FilterMatchMode } from "primevue/api";

import DatatableService from "@/service/Datatable/DatatableService";
import ColumsService from "@/service/Colums/ColumsService";
import TipoColumnService from "@/service/TipoColumn/TipoColumnService";
import RegistroService from "@/service/Registro/RegistroService";
import UsersService from "@/service/Users/UsersService";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      selectedDatatable: null,
      usuarios: null,
      datatables: null,
      datatable: {
        id: null,
        nome: null,
        status: true,
        dt_cadastro: null,
        dt_atualizacao: null,
      },
      colums: {
        params: [
          {
            id: null,
            nome: null,
            tipo: null,
            datatable: null,
            ordem: 0,
          },
        ],
      },
      selectedColum: null,
      tipoColumns: null,
      datatableDialog: false,
      configuradorDialog: false,
      permissoesDialog: false,
      selectedTipo: null,
      titleDialog: null,
      submitted: false,
      deleteDatatableDialog: false,
      deleteColumToDatatable: false,
      filters: {},
    };
  },
  datatableService: null,
  columsService: null,
  tipoColumnService: null,
  registroService: null,
  usersService: null,
  validations() {
    return {
      datatable: {
        nome: {
          required,
        },
        status: {
          required,
        },
      },
    };
  },
  created() {
    this.datatableService = new DatatableService();
    this.columsService = new ColumsService();
    this.tipoColumnService = new TipoColumnService();
    this.registroService = new RegistroService();
    this.usersService = new UsersService();
    this.initFilters();
  },
  mounted() {
    this.findAllDatatable();
    this.findAllTipoColumn();
    this.findAllUsers();
  },
  methods: {
    showCreateDatatable() {
      this.titleDialog = "NOVO DATATABLE";
      this.datatableDialog = true;
    },
    showUpdateDatatable(datatable) {
      this.datatable = datatable;
      this.titleDialog = datatable.nome.toUpperCase();
      this.datatableDialog = true;
    },
    showConfigurador(datatable) {
      this.datatable = datatable;
      datatable.dt_atualizacao == ""
        ? (datatable.dt_atualizacao = "NÃO ATUALIZADO")
        : datatable.dt_atualizacao;

      this.findColumsByDatatableId(datatable.id);
      this.titleDialog = this.datatable.nome;
      this.selectedDatatable = datatable;
      this.configuradorDialog = true;
    },
    showDeleteDataTable(datatable) {
      this.datatable = datatable;
      this.deleteDatatableDialog = true;
    },
    showPreviewDataTable(datatable) {
      this.selectedDatatable = datatable;
      let url =
        "https://transparencia.saude.pa.gov.br/#/preview?dt=" +
        //"http://192.168.131.102:8081/transparencia/#/preview?dt=" +
        this.selectedDatatable.id +
        "&name=" +
        this.selectedDatatable.nome;

      window.open(url, "_blank");
    },
    showPermissoesDataTable(datatable) {
      this.datatable = datatable;
      datatable.dt_atualizacao == ""
        ? (datatable.dt_atualizacao = "NÃO ATUALIZADO")
        : datatable.dt_atualizacao;
      this.titleDialog = this.datatable.nome;
      this.selectedDatatable = datatable;
      //console.log(datatable);
      this.permissoesDialog = true;
    },
    hideDialogs() {
      this.datatable = {
        id: null,
        nome: null,
        status: true,
        dt_cadastro: null,
        dt_atualizacao: null,
        setor: this.$Token.tokenParams().setor,
      };
      this.submitted = false;
      this.datatableDialog = false;
      this.configuradorDialog = false;
      this.permissoesDialog = false;
      this.deleteDatatableDialog = false;
      this.deleteColumToDatatable = false;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    addColumn() {
      this.registroService
        .findByDatatableId(this.selectedDatatable.id)
        .then((data) => {
          if (data.length === 0) {
            this.colums.params.push({
              id: null,
              nome: null,
              tipo: null,
            });
          } else {
            this.$toast.add({
              severity: "info",
              summary: "Alerta!",
              group: "tl",
              detail:
                "Atenção! Não é permitido inserção de novas colunas, pois já se encontra dados de registros para essa tabela. A inserção de novas colunas acarretará problemas relacionados a estrutura da tabela. Em caso de inserção de novas colunas é necessário que haja exclusão destes registros.",
            });
          }
        });
    },
    removeColumn(datatable) {
      this.selectedColum = datatable;
      this.deleteColumToDatatable = true;
    },
    findAllDatatable() {
      this.datatableService
        .findAll()
        .then((data) => {
          this.datatables = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    findColumsByDatatableId(idDatatable) {
      this.columsService
        .findByDatatableId(idDatatable)
        .then((data) => {
          this.colums.params = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    findAllTipoColumn() {
      this.tipoColumnService
        .findAll()
        .then((data) => {
          this.tipoColumns = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    findAllUsers() {
      this.usersService
        .findAll()
        .then((data) => {
          this.usuarios = data.content;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    async saveOrUpdateDataTable(isFormValid) {
      this.submitted = true;
      if (!isFormValid) {
        return;
      }
      if (this.datatable.id === null) {
        this.datatableService
          .create(this.datatable)
          .then((data) => {
            this.$msgSuccess(data);
            this.findAllDatatable();
            this.hideDialogs();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$msgWarn(null);
            }
          });
      } else {
        this.datatable.dt_atualizacao = null;
        this.datatableService
          .update(this.datatable.id, this.datatable)
          .then((data) => {
            this.$msgSuccess(data);
            this.findAllDatatable();
            this.hideDialogs();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$msgWarn(null);
            }
          });
      }
    },
    saveOrUpdateColumsToDataTable() {
      this.submitted = true;
      this.colums.params.forEach((element) => {
        element.datatable = {
          id: this.selectedDatatable.id,
        };
        if (element.id === null) {
          this.columsService
            .create(element)
            .then((data) => {
              this.$msgSuccess(data);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                this.$msgWarn(null);
              }
            });
        } else {
          this.columsService
            .update(element.id, element)
            .then((data) => {
              this.$msgSuccess(data);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                this.$msgWarn(null);
              }
            });
        }
        //console.log(this.colums.params.length);
        if (
          this.colums.params.length - 1 ===
          this.colums.params.lastIndexOf(element)
        ) {
          //console.log("aki");
          this.hideDialogs();
        }
      });

      //array.lastIndexOf(this.colums.params);
    },
    updateStatus(datatable) {
      this.datatable = datatable;
      if (this.datatable.status === true) {
        this.datatableService
          .updateStatus(this.datatable.id, "false")
          .then(() => {
            this.findAllDatatable();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$msgWarn(null);
            } else {
              this.$msgErro(error);
            }
          });
      } else {
        this.datatableService
          .updateStatus(this.datatable.id, "true")
          .then(() => {
            this.findAllDatatable();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$msgWarn(null);
            } else {
              this.$msgErro(error);
            }
          });
      }
    },
    deleteDatatable() {
      this.datatableService
        .delete(this.datatable.id)
        .then((data) => {
          this.$msgSuccess(data);
          this.findAllDatatable();
          this.hideDialogs();
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          } else {
            this.$msgErro(error);
          }
        });
    },
    deleteColumDatatable() {
      if (this.selectedColum.id === null) {
        this.colums.params.pop();
        this.deleteColumToDatatable = false;
      } else {
        this.columsService
          .delete(this.selectedColum.id)
          .then((data) => {
            this.$msgSuccess(data);
            this.colums.params.pop(this.selectedColum.id);
            this.deleteColumToDatatable = false;
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$msgWarn(null);
            } else {
              this.$msgErro(error);
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  justify-content: space-between;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-invalid {
  color: red;
}
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-Administrador {
    background: #feedaf;
    color: #8a5340;
  }
  &.status-Operador {
    background: #c0c0c0;
    color: #000000;
  }
  &.status-Demonstracao {
    background: #b3e5fc;
    color: #23547b;
  }
  &.status-Consultor {
    background: #eccfff;
    color: #694382;
  }
  &.status-true {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-false {
    background: #ffcdd2;
    color: #c63737;
  }
}

.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none !important;
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }
      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>
